import React from 'react'
import styled from '@emotion/styled'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useNavigation } from '@hmn/rtl-web-core/hooks'

import { Icon, NetLogoIcon, RssIcon, RtlHrLogo } from '../Icon'
import { Link } from '../Link'
import { Menu, menuVariants, Navigation, navigationVariants } from '../Navigation'
import styles from './Footer.style'

const FooterStyled = styled.footer(props => ({ ...styles(props) }))

function Footer({ navigationData, ...rest }) {
    const [infoLinks, digitalProductsLinks, partnerLinks] = useNavigation(navigationData, [
        'info',
        'digital',
        'partner'
    ])
    const isAmp = useAmp()
    const gtmData = {
        eventCategory: 'Navigation',
        eventLabel: 'Navigation Item',
        eventValue: 'Footer'
    }
    const hasInfo = !!infoLinks?.length
    const hasDigital = !!digitalProductsLinks?.length
    const hasPartners = !!partnerLinks?.length

    return (
        <FooterStyled className="intextAdIgnore" isAmp={isAmp} {...rest}>
            {navigationData && (
                <Navigation
                    navigationData={navigationData}
                    className="Footer_main"
                    variant={navigationVariants.FOOTER}
                />
            )}
            <div className="Footer_container">
                <div className="Footer_info">
                    <div className="Footer_info_left">
                        {hasInfo && (
                            <Menu
                                className="Footer_info_menu"
                                variant={menuVariants.INFO}
                                gtmData={gtmData}
                                links={infoLinks}
                                highlightActive={false}
                            />
                        )}
                        <Link href="/rss" className="Footer_rss">
                            <Icon icon={RssIcon} viewBox="0 0 15 15" /> RSS
                        </Link>
                    </div>

                    <div className="Footer_info_right">
                        <Link href="https://www.rtl.hr/" isExternal className="Footer_logo_rtl" title="RTL - Hrvatska">
                            <Icon icon={RtlHrLogo} viewBox="00 0 90 30" />
                        </Link>
                        <Link href="/" as="/" className="Footer_logo_net" title="Net.hr">
                            <Icon icon={NetLogoIcon} viewBox="0 0 90 22" />
                        </Link>
                    </div>
                </div>
                {(hasDigital || hasPartners) && (
                    <div className="Footer_partners">
                        {hasDigital && (
                            <Menu
                                className="Footer_partners_item"
                                title="Rtl Digitalni Proizvodi"
                                variant={menuVariants.PARTNER}
                                gtmData={gtmData}
                                links={digitalProductsLinks}
                                highlightActive={false}
                            />
                        )}
                        {hasPartners && (
                            <Menu
                                className="Footer_partners_item"
                                title="Partnerski portali"
                                variant={menuVariants.PARTNER}
                                gtmData={gtmData}
                                links={partnerLinks}
                                highlightActive={false}
                            />
                        )}
                    </div>
                )}

                <span className="Footer_signature">&copy;{new Date().getFullYear()}. Sva prava pridržana.</span>
            </div>
        </FooterStyled>
    )
}

Footer.propTypes = { navigationData: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)]) }

Footer.defaultProps = {
    navigationData: null
}

export default withErrorBoundary(Footer, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Footer]: ', error, componentStack)
    }
})
