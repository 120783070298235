/* eslint-disable react/no-unknown-property */
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { Media } from '@hmn/rtl-web-core/components/Media'
import { useBreakpoints } from '@hmn/rtl-web-core/hooks'
import { UUID_REGEXP } from '@hmn/rtl-web-core/next-config/regexp'

import { FreemailButton, freemailButtonVariants } from '../FreemailButton'
import { ArrowLeftAltIcon, Icon } from '../Icon'
import { Menu, menuVariants, NavigationButton } from '../Navigation'
import SubavHeader from '../Navigation/SubavHeader.component'
import SubavLogosHeader from '../Navigation/SubavLogosHeader.component'
import { SearchButton } from '../Search'
import { NetHrLogo } from './components/NetHrLogo'
import styles from './Header.style'
// import { useHeaderState } from './useHeaderState'

const HeaderStyled = styled.div(props => ({ ...styles(props) }))

const mobileLogosSubnav = [
    {
        title: 'Net.hr',
        href: '/',
        logoUrl: '/images/navigation/nethr.svg',
        class: 'nethr',
        target: '_self'
    },
    {
        title: 'Danas.hr',
        href: '/danas',
        logoUrl: '/images/navigation/danashr.svg',
        class: 'danashr',
        target: '_self'
    },
    {
        title: 'Voyo Rtl.hr',
        href: 'https://voyo.rtl.hr/',
        logoUrl: '/images/navigation/voyo.svg',
        class: 'voyo',
        target: '_blank'
    },
    {
        title: 'Rtl.hr',
        href: 'https://rtl.hr/',
        logoUrl: '/images/navigation/rtlhr.svg',
        class: 'rtlhr',
        target: '_blank'
    },
    {
        title: 'Žena.hr',
        href: 'https://zena.net.hr/',
        logoUrl: '/images/navigation/zenahr.svg',
        class: 'zenahr',
        target: '_blank'
    }
]

const entityRegex = new RegExp(UUID_REGEXP, 'gi')
const navigationIsOpenSelector = state => state.navigation.isOpen
function MobileButtons({ className }) {
    const isOpen = useSelector(navigationIsOpenSelector)
    const router = useRouter()

    const isSingle = router.query.slugs?.join('/')?.match(entityRegex)?.length

    const handleBack = useCallback(() => {
        if (window?.sessionStorage?.getItem('internal-route-origin')) {
            router.back()
        } else {
            router.push('/')
        }
    }, [router])
    return (
        <Media lessThan="md" scaleDown>
            <div className={clsx('Header_mainButton', className)}>
                <div className={clsx('Header_icon', !isOpen ? 'open' : 'closed')}>
                    {isSingle ? (
                        <Icon
                            onClick={handleBack}
                            className="Header_backButton"
                            viewBox="0 0 11 18"
                            icon={ArrowLeftAltIcon}
                        />
                    ) : (
                        <SearchButton />
                    )}
                </div>
                <div className={clsx('Header_icon', isOpen ? 'open' : 'closed')}>
                    <FreemailButton variant={freemailButtonVariants.HEADER} />
                </div>
            </div>
        </Media>
    )
}
function MobileButtonsDeviceParent({ className }) {
    const [isDesktop] = useBreakpoints('md')
    if (isDesktop) {
        return null
    }
    return <MobileButtons className={className} />
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Header({ activeCategory, activeSubcategory, categoryName, navList, ...rest }) {
    const subnavList = navList?.find(item => item.href === activeCategory)
    const navListWithoutDanas = navList?.filter(item => item.href !== '/danas')

    return (
        <HeaderStyled open {...rest}>
            <header className="Header">
                <div className="Header_container">
                    <MobileButtonsDeviceParent />
                    <div className="Header_left">
                        <NetHrLogo className="Header_logo" logoOpen />
                        <NetHrLogo className={clsx('Header_logo is_faux hideMobSm', `is_${categoryName}`)} logoOpen />
                        <Menu
                            className="Header_navigation hideMobSm"
                            links={navListWithoutDanas}
                            variant={menuVariants.HEADER}
                            higlightActive
                        />
                    </div>
                    <div className="Header_right">
                        <Media greaterThan="sm" scaleDown>
                            <FreemailButton variant={freemailButtonVariants.HEADER} />
                            <div className="Header_mainButton">
                                <div className="Header_icon">
                                    <SearchButton />
                                </div>
                            </div>
                        </Media>

                        {navList?.length > 0 && (
                            <div className="Header_mainButton">
                                <NavigationButton />
                            </div>
                        )}
                    </div>
                </div>
                {!!subnavList?.items?.length && (
                    <div className="Header_subnavigation hideMobSm">
                        <SubavHeader
                            navigationData={subnavList.items}
                            activeCategory={activeCategory}
                            activeSubcategory={activeSubcategory}
                            categoryName={categoryName}
                        />
                    </div>
                )}
                <div className="Header_subnavigation hideDeskSm">
                    <SubavLogosHeader navigationData={mobileLogosSubnav} />
                </div>
            </header>
        </HeaderStyled>
    )
}
Header.propTypes = {
    activeCategory: PropTypes.string,
    activeSubcategory: PropTypes.string,
    categoryName: PropTypes.string,
    navList: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)])
}

Header.defaultProps = {
    activeCategory: '',
    activeSubcategory: '',
    categoryName: '',
    navList: null
}
export default withErrorBoundary(Header, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Header]: ', error, componentStack)
    }
})
