/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
// import clsx from 'clsx'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useActiveCategory } from '@hmn/rtl-web-core/hooks'

import { DanasLogoIcon, NetLogoColorIcon } from '../../../Icon'
import { Link } from '../../../Link'
import styles from './NetHrLogo.style'

const NetHrLogoStyled = styled.div(props => ({ ...styles(props) }))

function NetHrLogo({ className, size, logoColor, logoOpen, ...rest }) {
    const { color: activeWidgetGroupColor } = useSelector(state => state.navigation)
    // eslint-disable-next-line no-unused-vars
    const [, , categoryColor] = useActiveCategory()
    return (
        <NetHrLogoStyled
            className={className}
            logoColor={activeWidgetGroupColor || categoryColor}
            logoOpen={activeWidgetGroupColor || logoOpen}
            {...rest}>
            <Link href="/" className="is_netHrLogo">
                <NetLogoColorIcon viewBox="0 0 100 18" width="120" {...rest} />
            </Link>
            <Link href="/danas/" className="is_danasHrLogo">
                <DanasLogoIcon viewBox="0 0 100 18" width="120" {...rest} />
            </Link>
        </NetHrLogoStyled>
    )
}

NetHrLogo.propTypes = {
    className: PropTypes.string,
    logoColor: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    logoOpen: PropTypes.bool
}

NetHrLogo.defaultProps = {
    className: undefined,
    logoColor: undefined,
    size: 'inherit',
    logoOpen: true
}

export default withErrorBoundary(NetHrLogo, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[NetHrLogo]: ', error, componentStack)
    }
})
